export const formatMonth = (monthString) => {
    const [year, month] = monthString.split("-")
    const formattedDate = new Date(year, month - 1).toLocaleString("en-HK", {
        month: "long",
        year: "numeric",
    })

    return formattedDate
}

export const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-")
    const formattedDate = new Date(year, month - 1, day).toLocaleString("en-HK", {
        day: "numeric",
        month: "long",
    })

    return formattedDate
}

export const formatTime = (timestamp) => {
    const date = new Date(timestamp)
    let hours = date.getHours()
    const minutes = date.getMinutes()
    let period = "am"

    if (hours >= 12) {
        period = "pm"
        if (hours > 12) {
            hours -= 12
        }
    }

    const formattedHours = hours.toString().padStart(2, "0")
    const formattedMinutes = minutes.toString().padStart(2, "0")

    return `${formattedHours}:${formattedMinutes} ${period}`
}
