import { useEffect, useMemo } from "react"
import { formatTime, formatDate, formatMonth } from "./shared/dateTime"
import { responsive } from "./shared/responsive"
import { stringToColorDot } from "./shared/stringToColorDot"
import { shiftData } from "./data/shiftData"
import { Button, Typography, Stack, Avatar } from "@mui/material"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "./Shifts.scss"

const Shifts = ({
    searchTerm,
    statusFilter,
    selectedShiftIDs,
    setSelectedShiftIDs,
    filteredShifts,
    setFilteredShifts,
    updateShifts,
}) => {
    // Sort shifts by start time and cache in memo
    const sortedShifts = useMemo(() => {
        return shiftData.sort((a, b) => new Date(b.shiftStart) - new Date(a.shiftStart))
    }, [])

    useEffect(() => {
        // Filter shifts based on search term
        const searched = sortedShifts.filter(
            (shift) =>
                shift.firstName.toLowerCase().includes(searchTerm) || shift.lastName.toLowerCase().includes(searchTerm)
        )

        // Filter shifts based on shift status
        setFilteredShifts(statusFilter !== "ALL" ? searched.filter((shift) => shift.status === statusFilter) : searched)
    }, [searchTerm, statusFilter, setFilteredShifts, sortedShifts])

    const groupedShifts = {}

    // Group shifts by month and date
    filteredShifts.forEach((shift) => {
        const shiftStart = new Date(shift.shiftStart)
        const day = shiftStart.getDate()
        const month = shiftStart.getMonth() + 1
        const year = shiftStart.getFullYear()
        const yearMonth = `${year}-${month}`
        const yearMonthDay = `${year}-${month}-${day}`

        // Create shift month if it does not exist
        if (!groupedShifts[yearMonth]) {
            groupedShifts[yearMonth] = []
        }

        // Create shift date if it does not exist
        if (!groupedShifts[yearMonth][yearMonthDay]) {
            groupedShifts[yearMonth][yearMonthDay] = []
        }

        groupedShifts[yearMonth][yearMonthDay].push(shift)
    })

    // Handle multi select of shifts
    const handleSelectShiftIDs = (shiftID) => {
        if (selectedShiftIDs.includes(shiftID)) {
            setSelectedShiftIDs((prev) => prev.filter((id) => id !== shiftID))
        } else {
            setSelectedShiftIDs((prev) => [...prev, shiftID])
        }
    }

    return (
        <main className="shift">
            <Carousel responsive={responsive}>
                {Object.entries(groupedShifts).map(([month, shiftsByDay]) => (
                    <div key={month} className="shift__month">
                        <div className="shift__month__title">
                            <h2>{formatMonth(month)}</h2>
                        </div>
                        <div className="shift__cards" key={month}>
                            {Object.entries(shiftsByDay).map(([day, shifts]) => (
                                <div key={day}>
                                    <div className="shift__day">
                                        <h3 className="shift__day__title">{formatDate(day)}</h3>
                                    </div>
                                    {shifts.map((shift) => {
                                        const pending = shift.status === "PENDING"
                                        const selected = selectedShiftIDs.includes(shift.id)

                                        return (
                                            <div
                                                key={shift.id}
                                                className={`shift__card ${pending ? "shift__card__pending" : "shift__card__processed"
                                                    } ${selected ? "shift__card__selected" : ""}`}
                                                onClick={pending ? () => handleSelectShiftIDs(shift.id) : null}
                                            >
                                                <h4>
                                                    {formatTime(shift.shiftStart)} — {formatTime(shift.shiftEnd)}
                                                </h4>
                                                <p className="shift__card__employee">
                                                    {shift.firstName} {shift.lastName}{" "}
                                                    <span className="shift__card__employee-id">{shift.employeeID}</span>
                                                </p>
                                                <Stack direction="row" alignItems="center">
                                                    <Avatar {...stringToColorDot(shift.role)} />
                                                    <Typography
                                                        variant="body2"
                                                        color={selected ? "text.light" : "text.secondary"}
                                                    >
                                                        {shift.role}
                                                    </Typography>
                                                </Stack>
                                                {pending ? (
                                                    selected ? (
                                                        <div className="shift__card__prompt">Selected</div>
                                                    ) : (
                                                        <div className="shift__card__button">
                                                            <Button
                                                                variant="outlined"
                                                                style={{ marginLeft: "5px" }}
                                                                color="error"
                                                                size="small"
                                                                onClick={(e) => updateShifts(e, [shift.id], "DECLINED")}
                                                            >
                                                                Decline
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                style={{
                                                                    backgroundColor: "#00AF7C",
                                                                    marginLeft: "8px",
                                                                }}
                                                                size="small"
                                                                onClick={(e) =>
                                                                    updateShifts(e, [shift.id], "CONFIRMED")
                                                                }
                                                            >
                                                                Confirm
                                                            </Button>
                                                        </div>
                                                    )
                                                ) : (
                                                    <div className="shift__card__status">{shift.status}</div>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </Carousel>
        </main>
    )
}

export default Shifts
