import { useState } from "react"
import Header from "./Header"
import Shifts from "./Shifts"
import { Snackbar, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import "./App.scss"

function App() {
    const [searchTerm, setSearchTerm] = useState("")
    const [statusFilter, setStatusFilter] = useState("ALL")
    const [filteredShifts, setFilteredShifts] = useState([])
    const [selectedShiftIDs, setSelectedShiftIDs] = useState([])

    // Handle alert for successful confirm and decline
    const [openAlert, setOpenAlert] = useState(false)

    const action = (
        <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpenAlert(false)}>
            <CloseIcon fontSize="small" />
        </IconButton>
    )

    // Confirm or decline shift on user instruction
    const updateShifts = (e, shiftIDs, updatedStatus) => {
        const updatedShifts = filteredShifts.map((shift) => {
            if (shiftIDs.includes(shift.id)) {
                return { ...shift, status: updatedStatus }
            } else {
                return shift
            }
        })

        e.stopPropagation()
        setOpenAlert(true)
        setFilteredShifts(updatedShifts)
        setSelectedShiftIDs([])
    }

    return (
        <>
            <Header
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
                selectedShiftIDs={selectedShiftIDs}
                updateShifts={updateShifts}
            />
            <Shifts
                searchTerm={searchTerm}
                statusFilter={statusFilter}
                selectedShiftIDs={selectedShiftIDs}
                setSelectedShiftIDs={setSelectedShiftIDs}
                filteredShifts={filteredShifts}
                setFilteredShifts={setFilteredShifts}
                updateShifts={updateShifts}
            />
            <Snackbar
                open={openAlert}
                autoHideDuration={2000}
                onClose={() => setOpenAlert(false)}
                message="Success"
                action={action}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            />
        </>
    )
}

export default App
