export const shiftData = [
    {
        id: "08e2f959-663b-474b-b870-0e30c6cd66b6",
        employeeID: 39861,
        firstName: "Manuel",
        lastName: "Jones",
        shiftStart: "2023-02-04T13:29:04.262944",
        shiftEnd: "2023-02-04T22:29:04.262944",
        role: "Critical Care",
        status: "DECLINED",
    },
    {
        id: "b85c768a-a38f-4b07-9140-9d3bfdae6076",
        employeeID: 26895,
        firstName: "Dennis",
        lastName: "Johnson",
        shiftStart: "2022-05-19T21:24:56.262944",
        shiftEnd: "2022-05-19T23:24:56.262944",
        role: "Palliative Care",
        status: "DECLINED",
    },
    {
        id: "a4a44539-5dd0-4cb0-86d7-c8be0ac64e43",
        employeeID: 85259,
        firstName: "Jonathon",
        lastName: "Moran",
        shiftStart: "2023-03-10T00:45:45.262944",
        shiftEnd: "2023-03-10T05:45:45.262944",
        role: "Occupational Health",
        status: "PENDING",
    },
    {
        id: "83c598d7-1e84-4289-b733-b0eeb1e40c39",
        employeeID: 6568,
        firstName: "David",
        lastName: "Mitchell",
        shiftStart: "2023-08-24T22:53:24.262944",
        shiftEnd: "2023-08-25T01:53:24.262944",
        role: "Medicine Administration",
        status: "PENDING",
    },
    {
        id: "2923ee12-ba42-488a-ae69-6ee68e8bb73e",
        employeeID: 10344,
        firstName: "Lori",
        lastName: "Hughes",
        shiftStart: "2023-01-29T23:36:30.262944",
        shiftEnd: "2023-01-30T09:36:30.262944",
        role: "Medicine Administration",
        status: "CONFIRMED",
    },
    {
        id: "eee7d2c9-902c-4656-b374-d6c688537d1a",
        employeeID: 49969,
        firstName: "Scott",
        lastName: "Phillips",
        shiftStart: "2022-08-19T12:28:30.262944",
        shiftEnd: "2022-08-19T16:28:30.262944",
        role: "Rehabilitation",
        status: "PENDING",
    },
    {
        id: "655672bc-cd5c-4e87-94cd-8433587cc2e8",
        employeeID: 53874,
        firstName: "Jessica",
        lastName: "Horn",
        shiftStart: "2023-04-19T20:52:11.262944",
        shiftEnd: "2023-04-20T05:52:11.262944",
        role: "Community Health",
        status: "DECLINED",
    },
    {
        id: "7ab30e36-addc-4ee2-bbca-ac55d061fc33",
        employeeID: 60194,
        firstName: "Anna",
        lastName: "Hoover",
        shiftStart: "2023-05-02T20:40:04.262944",
        shiftEnd: "2023-05-03T08:40:04.262944",
        role: "Palliative Care",
        status: "CONFIRMED",
    },
    {
        id: "1c8e3c38-7550-4614-bafd-221891081e04",
        employeeID: 54763,
        firstName: "Jessica",
        lastName: "Horn",
        shiftStart: "2022-11-08T13:31:33.262944",
        shiftEnd: "2022-11-08T19:31:33.262944",
        role: "Wound Care",
        status: "PENDING",
    },
    {
        id: "c4b2cc58-f736-42fb-8535-98f17323ea35",
        employeeID: 85555,
        firstName: "Travis",
        lastName: "Camacho",
        shiftStart: "2022-03-19T23:33:46.262944",
        shiftEnd: "2022-03-20T08:33:46.262944",
        role: "Geriatric Care",
        status: "DECLINED",
    },
    {
        id: "e0515091-4255-4330-9674-d94b680b0f0c",
        employeeID: 25744,
        firstName: "Paul",
        lastName: "Davis",
        shiftStart: "2022-12-23T15:43:59.262944",
        shiftEnd: "2022-12-23T19:43:59.262944",
        role: "Community Health",
        status: "DECLINED",
    },
    {
        id: "db2ae45e-5369-400a-900e-bcf67fccdd0a",
        employeeID: 98071,
        firstName: "Michael",
        lastName: "Baker",
        shiftStart: "2023-03-25T09:19:23.262944",
        shiftEnd: "2023-03-25T10:19:23.262944",
        role: "Palliative Care",
        status: "PENDING",
    },
    {
        id: "42b25e3b-e6cb-4614-b12d-3bfb1c16be10",
        employeeID: 52359,
        firstName: "Tracy",
        lastName: "Anderson",
        shiftStart: "2022-06-03T23:28:11.262944",
        shiftEnd: "2022-06-04T10:28:11.262944",
        role: "Critical Care",
        status: "PENDING",
    },
    {
        id: "f44a9b7d-fe28-4ecf-8016-a32859872cf0",
        employeeID: 27474,
        firstName: "Jill",
        lastName: "Humphrey",
        shiftStart: "2023-05-17T10:14:10.262944",
        shiftEnd: "2023-05-17T18:14:10.262944",
        role: "Pediatric Care",
        status: "DECLINED",
    },
    {
        id: "7639147f-fbb9-401d-b594-123d708bfbb4",
        employeeID: 7094,
        firstName: "Carlos",
        lastName: "Nichols",
        shiftStart: "2023-01-15T14:54:56.262944",
        shiftEnd: "2023-01-16T01:54:56.262944",
        role: "Rehabilitation",
        status: "CONFIRMED",
    },
    {
        id: "8a2aab6e-af86-4c2d-a1f8-fddf6a744aaf",
        employeeID: 93651,
        firstName: "Edward",
        lastName: "Norris",
        shiftStart: "2022-03-28T15:04:00.262944",
        shiftEnd: "2022-03-29T02:04:00.262944",
        role: "Rehabilitation",
        status: "CONFIRMED",
    },
    {
        id: "4c6282a1-8311-4c43-927d-5f1877dca270",
        employeeID: 27189,
        firstName: "Jeremiah",
        lastName: "Acosta",
        shiftStart: "2023-01-29T00:54:21.262944",
        shiftEnd: "2023-01-29T11:54:21.262944",
        role: "Occupational Health",
        status: "CONFIRMED",
    },
    {
        id: "adae220d-4747-449b-a5ba-2fbc7b8b26a6",
        employeeID: 59368,
        firstName: "Dawn",
        lastName: "Flores",
        shiftStart: "2023-07-26T20:06:35.262944",
        shiftEnd: "2023-07-26T07:06:35.262944",
        role: "Occupational Health",
        status: "PENDING",
    },
    {
        id: "ef9a2dd8-3336-41e6-bede-aa64958c2903",
        employeeID: 6592,
        firstName: "Jeffery",
        lastName: "Brown",
        shiftStart: "2023-05-17T16:16:44.262944",
        shiftEnd: "2023-05-17T22:16:44.262944",
        role: "Pediatric Care",
        status: "PENDING",
    },
    {
        id: "28877055-7e50-4dc1-9daf-8dd5c973cb4b",
        employeeID: 41005,
        firstName: "Scott",
        lastName: "Gallagher",
        shiftStart: "2023-05-17T19:18:56.262944",
        shiftEnd: "2023-05-17T21:18:56.262944",
        role: "Geriatric Care",
        status: "CONFIRMED",
    },
    {
        id: "5c868756-8797-4518-ab3b-7d65d01b71f7",
        employeeID: 63570,
        firstName: "David",
        lastName: "Todd",
        shiftStart: "2022-10-30T07:31:40.262944",
        shiftEnd: "2022-10-30T17:31:40.262944",
        role: "Pediatric Care",
        status: "PENDING",
    },
    {
        id: "20ab8333-3db6-4573-aee2-5578721a32ee",
        employeeID: 21267,
        firstName: "Karen",
        lastName: "Sullivan",
        shiftStart: "2022-08-21T10:10:06.262944",
        shiftEnd: "2022-08-21T11:10:06.262944",
        role: "Rehabilitation",
        status: "DECLINED",
    },
    {
        id: "ac275f15-f572-4ed0-9221-d8c3904a1563",
        employeeID: 42560,
        firstName: "Michael",
        lastName: "Serrano",
        shiftStart: "2022-07-06T07:37:08.262944",
        shiftEnd: "2022-07-06T13:37:08.262944",
        role: "Occupational Health",
        status: "PENDING",
    },
    {
        id: "9af75e63-6ba0-490f-9d8c-f95af3865d72",
        employeeID: 14496,
        firstName: "Jonathan",
        lastName: "Sanders",
        shiftStart: "2023-08-29T06:54:03.262944",
        shiftEnd: "2023-08-29T15:54:03.262944",
        role: "Occupational Health",
        status: "PENDING",
    },
    {
        id: "90fb05e3-b5d0-4a98-b1af-7590ce113c72",
        employeeID: 82118,
        firstName: "Miguel",
        lastName: "Rodriguez",
        shiftStart: "2022-11-29T03:45:20.262944",
        shiftEnd: "2022-11-29T09:45:20.262944",
        role: "Pediatric Care",
        status: "PENDING",
    },
    {
        id: "1d4a2ac2-d90d-4413-bced-d8c3a39fa666",
        employeeID: 38991,
        firstName: "Jeremy",
        lastName: "Waters",
        shiftStart: "2022-03-23T20:17:19.262944",
        shiftEnd: "2022-03-24T06:17:19.262944",
        role: "Palliative Care",
        status: "PENDING",
    },
    {
        id: "1187c9df-f15a-4e1b-b890-66edcaa5a1a9",
        employeeID: 44153,
        firstName: "Lisa",
        lastName: "Byrd",
        shiftStart: "2023-03-09T03:06:58.262944",
        shiftEnd: "2023-03-09T05:06:58.262944",
        role: "Physical Therapy",
        status: "CONFIRMED",
    },
    {
        id: "36403879-2467-46a1-a863-066e085a02ce",
        employeeID: 60049,
        firstName: "Joshua",
        lastName: "Brown",
        shiftStart: "2023-02-27T05:18:34.262944",
        shiftEnd: "2023-02-27T12:18:34.262944",
        role: "Community Health",
        status: "PENDING",
    },
    {
        id: "299b292e-d873-4572-9c53-ca9d26b8ccc6",
        employeeID: 67196,
        firstName: "Joseph",
        lastName: "Davenport",
        shiftStart: "2023-06-21T17:19:44.262944",
        shiftEnd: "2023-06-22T04:19:44.262944",
        role: "Community Health",
        status: "PENDING",
    },
    {
        id: "9d3699c5-ce83-4208-ae26-7b4dae3b8c3a",
        employeeID: 44487,
        firstName: "Francis",
        lastName: "Stewart",
        shiftStart: "2022-12-07T17:09:27.262944",
        shiftEnd: "2022-12-07T21:09:27.262944",
        role: "Geriatric Care",
        status: "DECLINED",
    },
    {
        id: "dfc952c6-ea1e-4cf1-9e99-37c9bbf10c24",
        employeeID: 70179,
        firstName: "Kaitlyn",
        lastName: "Estrada",
        shiftStart: "2022-05-23T16:28:10.262944",
        shiftEnd: "2022-05-24T02:28:10.262944",
        role: "Palliative Care",
        status: "PENDING",
    },
    {
        id: "bee14858-f420-4998-86db-d947e902e64e",
        employeeID: 87395,
        firstName: "Elizabeth",
        lastName: "Willis",
        shiftStart: "2022-05-27T13:12:19.262944",
        shiftEnd: "2022-05-27T17:12:19.262944",
        role: "Occupational Health",
        status: "CONFIRMED",
    },
    {
        id: "0dcf5a18-9e75-48bd-9f75-5d205aa647f4",
        employeeID: 66205,
        firstName: "Robert",
        lastName: "Henry",
        shiftStart: "2022-11-28T18:14:36.262944",
        shiftEnd: "2022-11-29T00:14:36.262944",
        role: "Pediatric Care",
        status: "PENDING",
    },
    {
        id: "07964282-5fbd-47bf-ab9a-7be8f1030642",
        employeeID: 72990,
        firstName: "April",
        lastName: "Collins",
        shiftStart: "2022-10-14T10:58:07.262944",
        shiftEnd: "2022-10-14T12:58:07.262944",
        role: "Physical Therapy",
        status: "CONFIRMED",
    },
    {
        id: "8525c967-9861-4c4a-b3ba-b7535f095f64",
        employeeID: 91655,
        firstName: "Erin",
        lastName: "Scott",
        shiftStart: "2023-01-29T14:04:56.262944",
        shiftEnd: "2023-01-29T15:04:56.262944",
        role: "Pediatric Care",
        status: "DECLINED",
    },
    {
        id: "d6c2cd5a-0d58-4b6e-b6e1-9ba54d5ef647",
        employeeID: 90967,
        firstName: "Frank",
        lastName: "King",
        shiftStart: "2023-05-06T20:04:42.262944",
        shiftEnd: "2023-05-07T06:04:42.262944",
        role: "Community Health",
        status: "PENDING",
    },
    {
        id: "30a60972-e5f7-4955-aecb-0e8f105d6878",
        employeeID: 7173,
        firstName: "Tiffany",
        lastName: "Smith",
        shiftStart: "2022-10-02T21:42:52.262944",
        shiftEnd: "2022-10-03T03:42:52.262944",
        role: "Wound Care",
        status: "PENDING",
    },
    {
        id: "c568c204-e0f0-40b0-8f18-30335c36f3ac",
        employeeID: 82232,
        firstName: "Eric",
        lastName: "Wolf",
        shiftStart: "2023-06-02T13:23:41.262944",
        shiftEnd: "2023-06-02T19:23:41.262944",
        role: "Mental Health",
        status: "DECLINED",
    },
    {
        id: "4dfd8e89-e57a-4206-9d29-09cb642ff3a8",
        employeeID: 54338,
        firstName: "Lauren",
        lastName: "Wong",
        shiftStart: "2023-03-09T22:11:28.262944",
        shiftEnd: "2023-03-10T07:11:28.262944",
        role: "Pediatric Care",
        status: "CONFIRMED",
    },
    {
        id: "036c9252-2755-4feb-b44f-9556fe0925d7",
        employeeID: 12958,
        firstName: "Joseph",
        lastName: "Huerta",
        shiftStart: "2023-02-27T04:46:48.262944",
        shiftEnd: "2023-02-27T09:46:48.262944",
        role: "Physical Therapy",
        status: "PENDING",
    },
    {
        id: "adb9b3ce-2c8e-4bc5-9b1b-201f2a223c9a",
        employeeID: 15599,
        firstName: "Andrew",
        lastName: "Gamble",
        shiftStart: "2022-05-25T23:06:35.262944",
        shiftEnd: "2022-05-26T04:06:35.262944",
        role: "Community Health",
        status: "CONFIRMED",
    },
    {
        id: "956cf945-7296-43d3-8fc5-06bfabc2ac37",
        employeeID: 13491,
        firstName: "Kevin",
        lastName: "Harper",
        shiftStart: "2022-04-21T05:22:40.262944",
        shiftEnd: "2022-04-21T09:22:40.262944",
        role: "Palliative Care",
        status: "PENDING",
    },
    {
        id: "61655695-da1b-4447-bb19-9187a8925e0f",
        employeeID: 90963,
        firstName: "Lisa",
        lastName: "Lopez",
        shiftStart: "2022-09-06T20:53:43.262944",
        shiftEnd: "2022-09-07T06:53:43.262944",
        role: "Physical Therapy",
        status: "PENDING",
    },
    {
        id: "0cc1809c-5ba1-4976-96b0-48cacb61d3e0",
        employeeID: 7077,
        firstName: "Alex",
        lastName: "Juarez",
        shiftStart: "2022-06-21T00:29:08.262944",
        shiftEnd: "2022-06-21T10:29:08.262944",
        role: "Physical Therapy",
        status: "PENDING",
    },
    {
        id: "4b71c1f8-9b60-4aec-8bbf-7846a59ae6b1",
        employeeID: 37649,
        firstName: "Lynn",
        lastName: "Evans",
        shiftStart: "2023-05-04T23:40:41.262944",
        shiftEnd: "2023-05-05T03:40:41.262944",
        role: "Geriatric Care",
        status: "DECLINED",
    },
    {
        id: "13a107dd-409a-4e22-9e69-86835bb22348",
        employeeID: 85397,
        firstName: "Richard",
        lastName: "Hill",
        shiftStart: "2023-05-01T11:34:19.262944",
        shiftEnd: "2023-05-01T15:34:19.262944",
        role: "Pediatric Care",
        status: "DECLINED",
    },
    {
        id: "584b0249-1fde-4600-9e22-be185239c1e1",
        employeeID: 64926,
        firstName: "Brian",
        lastName: "George",
        shiftStart: "2022-10-30T07:39:15.262944",
        shiftEnd: "2022-10-30T10:39:15.262944",
        role: "Geriatric Care",
        status: "DECLINED",
    },
    {
        id: "a174f46f-f1a7-4213-987c-b040c148e3fa",
        employeeID: 50929,
        firstName: "Stacey",
        lastName: "Wells",
        shiftStart: "2023-03-23T08:31:58.262944",
        shiftEnd: "2023-03-23T13:31:58.262944",
        role: "Rehabilitation",
        status: "CONFIRMED",
    },
    {
        id: "609e374c-858e-4f2d-bdb1-c5fd5207cd5d",
        employeeID: 25402,
        firstName: "Andrea",
        lastName: "Longfield",
        shiftStart: "2023-04-27T02:59:13.262944",
        shiftEnd: "2023-04-27T04:59:13.262944",
        role: "Community Health",
        status: "PENDING",
    },
    {
        id: "71e647d8-2f13-478c-a82e-6156fa4f4470",
        employeeID: 75391,
        firstName: "Evan",
        lastName: "Lowe",
        shiftStart: "2023-04-27T22:35:12.262944",
        shiftEnd: "2023-04-28T00:35:12.262944",
        role: "Mental Health",
        status: "PENDING",
    },
    {
        id: "ca74cdab-6bb2-45f0-94b5-2acbfc3b1b15",
        employeeID: 53812,
        firstName: "Maria",
        lastName: "King",
        shiftStart: "2022-07-26T23:36:29.262944",
        shiftEnd: "2022-07-27T07:36:29.262944",
        role: "Geriatric Care",
        status: "PENDING",
    },
    {
        id: "387e8f72-c9a1-40d5-a5ba-5eb2bc01ad62",
        employeeID: 95204,
        firstName: "Gail",
        lastName: "Goodwin",
        shiftStart: "2022-08-23T23:19:46.262944",
        shiftEnd: "2022-08-24T04:19:46.262944",
        role: "Palliative Care",
        status: "PENDING",
    },
    {
        id: "616bcbbc-0f8b-4bc1-999a-a4128524be3d",
        employeeID: 15894,
        firstName: "Sarah",
        lastName: "Brown",
        shiftStart: "2022-10-03T05:06:41.262944",
        shiftEnd: "2022-10-03T07:06:41.262944",
        role: "Medicine Administration",
        status: "DECLINED",
    },
    {
        id: "4604a827-17f7-4196-bfd9-e8943a166b89",
        employeeID: 78167,
        firstName: "Sherry",
        lastName: "Ramsey",
        shiftStart: "2023-05-17T17:49:35.262944",
        shiftEnd: "2023-05-18T01:49:35.262944",
        role: "Pediatric Care",
        status: "DECLINED",
    },
    {
        id: "ed6a973b-2f30-4c8f-9cb9-8c7d4ac9919c",
        employeeID: 94386,
        firstName: "Alex",
        lastName: "Carter",
        shiftStart: "2022-03-31T16:47:03.262944",
        shiftEnd: "2022-04-01T04:47:03.262944",
        role: "Pediatric Care",
        status: "PENDING",
    },
    {
        id: "2d69c59a-f2ba-462f-8d75-77c9e8ed48f8",
        employeeID: 4031,
        firstName: "Susan",
        lastName: "Acosta",
        shiftStart: "2023-01-11T06:09:06.262944",
        shiftEnd: "2023-01-11T18:09:06.262944",
        role: "Geriatric Care",
        status: "CONFIRMED",
    },
    {
        id: "3b2248d6-39f0-497a-8a1d-ff1934262477",
        employeeID: 64191,
        firstName: "Misty",
        lastName: "Keith",
        shiftStart: "2023-02-27T18:04:10.262944",
        shiftEnd: "2023-02-28T06:04:10.262944",
        role: "Medicine Administration",
        status: "PENDING",
    },
    {
        id: "a35442e4-258d-4881-b2e6-237453d114f9",
        employeeID: 44531,
        firstName: "Rachel",
        lastName: "Blair",
        shiftStart: "2023-03-03T02:57:10.262944",
        shiftEnd: "2023-03-03T07:57:10.262944",
        role: "Community Health",
        status: "PENDING",
    },
    {
        id: "5df0cf50-808e-44f6-b099-92c35d9090ed",
        employeeID: 42092,
        firstName: "Karen",
        lastName: "Adams",
        shiftStart: "2022-08-18T01:33:36.262944",
        shiftEnd: "2022-08-18T12:33:36.262944",
        role: "Medicine Administration",
        status: "PENDING",
    },
    {
        id: "668e4a1c-f17d-4e15-bb75-259aa0c0a3a6",
        employeeID: 65987,
        firstName: "Andrew",
        lastName: "Burgess",
        shiftStart: "2023-08-29T09:18:43.262944",
        shiftEnd: "2023-08-29T10:18:43.262944",
        role: "Wound Care",
        status: "PENDING",
    },
    {
        id: "9b1e7ea6-d1f3-44c7-8c2d-5fd8ca55986f",
        employeeID: 19497,
        firstName: "Paul",
        lastName: "Smith",
        shiftStart: "2022-05-30T00:36:31.262944",
        shiftEnd: "2022-05-30T04:36:31.262944",
        role: "Wound Care",
        status: "CONFIRMED",
    },
    {
        id: "859261c6-6da9-4eb4-8fc0-99296867211e",
        employeeID: 7803,
        firstName: "Elizabeth",
        lastName: "Leonard",
        shiftStart: "2022-04-21T08:21:20.262944",
        shiftEnd: "2022-04-21T10:21:20.262944",
        role: "Wound Care",
        status: "DECLINED",
    },
    {
        id: "8a5d5f59-a20a-4f4f-8f15-0172825deb12",
        employeeID: 19306,
        firstName: "Stephanie",
        lastName: "Morrison",
        shiftStart: "2022-11-22T12:23:07.262944",
        shiftEnd: "2022-11-22T18:23:07.262944",
        role: "Community Health",
        status: "PENDING",
    },
    {
        id: "0053111f-9009-4738-96d0-b38958e5db07",
        employeeID: 34266,
        firstName: "Joseph",
        lastName: "Maxwell",
        shiftStart: "2023-04-27T08:09:15.262944",
        shiftEnd: "2023-04-27T13:09:15.262944",
        role: "Critical Care",
        status: "PENDING",
    },
    {
        id: "8d6daf1c-8d21-4e9c-a2a8-759f1e9fc48e",
        employeeID: 70303,
        firstName: "Laura",
        lastName: "Velazquez",
        shiftStart: "2023-07-14T12:19:55.262944",
        shiftEnd: "2023-07-14T22:19:55.262944",
        role: "Community Health",
        status: "DECLINED",
    },
    {
        id: "c6b03011-e457-4022-a694-3e0784df9983",
        employeeID: 79051,
        firstName: "Charles",
        lastName: "Rodriguez",
        shiftStart: "2023-02-05T19:24:28.262944",
        shiftEnd: "2023-02-05T23:24:28.262944",
        role: "Occupational Health",
        status: "CONFIRMED",
    },
    {
        id: "1e34a9a0-f487-41a8-9162-3f7aaddbd099",
        employeeID: 73427,
        firstName: "Jose",
        lastName: "Neal",
        shiftStart: "2022-05-28T06:05:36.262944",
        shiftEnd: "2022-05-28T17:05:36.262944",
        role: "Wound Care",
        status: "DECLINED",
    },
    {
        id: "b0570c5a-b593-401d-ad34-929171af35eb",
        employeeID: 23624,
        firstName: "Melissa",
        lastName: "Lewis",
        shiftStart: "2023-03-12T08:18:57.262944",
        shiftEnd: "2023-03-12T18:18:57.262944",
        role: "Medicine Administration",
        status: "PENDING",
    },
    {
        id: "d87f49c5-e62f-4cba-88c9-e72f16fdf603",
        employeeID: 19633,
        firstName: "Jeremy",
        lastName: "Miller",
        shiftStart: "2023-04-27T08:33:32.262944",
        shiftEnd: "2023-04-27T14:33:32.262944",
        role: "Geriatric Care",
        status: "PENDING",
    },
    {
        id: "619f6143-75a2-4da4-85db-360d76b8c560",
        employeeID: 34833,
        firstName: "Sean",
        lastName: "Flynn",
        shiftStart: "2023-02-18T00:22:44.262944",
        shiftEnd: "2023-02-18T11:22:44.262944",
        role: "Critical Care",
        status: "CONFIRMED",
    },
    {
        id: "6186286c-f5e8-4f34-b859-49b81b897098",
        employeeID: 36974,
        firstName: "Chad",
        lastName: "Murphy",
        shiftStart: "2022-09-11T23:00:53.262944",
        shiftEnd: "2022-09-12T06:00:53.262944",
        role: "Community Health",
        status: "CONFIRMED",
    },
    {
        id: "62f27335-413a-405d-9432-b4d4513c3b93",
        employeeID: 63689,
        firstName: "Seth",
        lastName: "Coleman",
        shiftStart: "2022-10-17T22:07:16.262944",
        shiftEnd: "2022-10-18T08:07:16.262944",
        role: "Physical Therapy",
        status: "CONFIRMED",
    },
    {
        id: "777f21f2-bb7b-40cd-8eb0-cf8c671c903e",
        employeeID: 9,
        firstName: "Jackson",
        lastName: "Baker",
        shiftStart: "2023-07-26T00:37:38.262944",
        shiftEnd: "2023-07-26T10:37:38.262944",
        role: "Geriatric Care",
        status: "CONFIRMED",
    },
    {
        id: "2fe5d9ae-9a8e-4328-91fa-f81f959dca84",
        employeeID: 61605,
        firstName: "Nathan",
        lastName: "Choi",
        shiftStart: "2022-04-05T13:17:43.262944",
        shiftEnd: "2022-04-05T18:17:43.262944",
        role: "Critical Care",
        status: "CONFIRMED",
    },
    {
        id: "ac542487-e58e-424e-876d-dff43184020d",
        employeeID: 93070,
        firstName: "Cindy",
        lastName: "Burke",
        shiftStart: "2022-12-31T15:16:00.262944",
        shiftEnd: "2022-12-31T21:16:00.262944",
        role: "Rehabilitation",
        status: "PENDING",
    },
    {
        id: "8f846b73-070b-4769-b08a-dcc00729c452",
        employeeID: 48869,
        firstName: "Billy",
        lastName: "Holmes",
        shiftStart: "2022-11-10T11:01:29.262944",
        shiftEnd: "2022-11-10T20:01:29.262944",
        role: "Mental Health",
        status: "CONFIRMED",
    },
    {
        id: "95f589b3-a43a-4dcc-86b2-7e85bbb0584b",
        employeeID: 77188,
        firstName: "Katherine",
        lastName: "Ross",
        shiftStart: "2022-07-07T13:53:56.262944",
        shiftEnd: "2022-07-07T16:53:56.262944",
        role: "Medicine Administration",
        status: "PENDING",
    },
    {
        id: "8cdad6b2-04af-4600-abbb-cbc9d7721f59",
        employeeID: 86031,
        firstName: "Abigail",
        lastName: "Hernandez",
        shiftStart: "2022-06-20T18:58:22.262944",
        shiftEnd: "2022-06-21T01:58:22.262944",
        role: "Physical Therapy",
        status: "PENDING",
    },
    {
        id: "0e5133b6-d842-49e2-89a0-89a05bfdc8e9",
        employeeID: 80601,
        firstName: "Samantha",
        lastName: "Murray",
        shiftStart: "2022-08-24T06:30:53.262944",
        shiftEnd: "2022-08-24T08:30:53.262944",
        role: "Community Health",
        status: "CONFIRMED",
    },
    {
        id: "39b33349-c258-4324-91f7-0c9c696abdb9",
        employeeID: 95222,
        firstName: "Joe",
        lastName: "Beck",
        shiftStart: "2022-09-19T20:13:22.262944",
        shiftEnd: "2022-09-20T05:13:22.262944",
        role: "Community Health",
        status: "CONFIRMED",
    },
    {
        id: "dbb04b6e-7990-4093-90fc-4d181ee22eb9",
        employeeID: 84863,
        firstName: "Pamela",
        lastName: "King",
        shiftStart: "2022-11-12T05:17:48.262944",
        shiftEnd: "2022-11-12T09:17:48.262944",
        role: "Community Health",
        status: "DECLINED",
    },
    {
        id: "52cf1d94-3d4d-4634-add6-e9d85ccacaf4",
        employeeID: 96233,
        firstName: "Daniel",
        lastName: "Lucero",
        shiftStart: "2023-05-04T07:36:34.262944",
        shiftEnd: "2023-05-04T18:36:34.262944",
        role: "Pediatric Care",
        status: "DECLINED",
    },
    {
        id: "559edf7f-f20c-4b55-b653-c164024fb63a",
        employeeID: 47685,
        firstName: "Danielle",
        lastName: "Roman",
        shiftStart: "2023-07-26T15:33:33.262944",
        shiftEnd: "2023-07-27T00:33:33.262944",
        role: "Medicine Administration",
        status: "PENDING",
    },
    {
        id: "f851a497-1d3a-4726-bdb8-a53ce166aa17",
        employeeID: 6810,
        firstName: "Kyle",
        lastName: "Mills",
        shiftStart: "2023-07-26T09:12:34.262944",
        shiftEnd: "2023-07-26T16:12:34.262944",
        role: "Pediatric Care",
        status: "CONFIRMED",
    },
    {
        id: "8dd02fe8-c4cc-41e0-a133-62e3700c8380",
        employeeID: 45569,
        firstName: "Tammy",
        lastName: "Thomas",
        shiftStart: "2023-01-13T13:01:05.262944",
        shiftEnd: "2023-01-13T19:01:05.262944",
        role: "Palliative Care",
        status: "DECLINED",
    },
    {
        id: "2205fc19-a76f-4e24-abfb-1f3a3ca7e2ec",
        employeeID: 13273,
        firstName: "Bryce",
        lastName: "Orr",
        shiftStart: "2023-07-03T19:06:02.262944",
        shiftEnd: "2023-07-04T04:06:02.262944",
        role: "Geriatric Care",
        status: "CONFIRMED",
    },
    {
        id: "0e6db91e-41f6-48a2-a3c7-081a4b27e8ce",
        employeeID: 53729,
        firstName: "Jill",
        lastName: "Rodriguez",
        shiftStart: "2022-11-15T18:31:41.262944",
        shiftEnd: "2022-11-15T20:31:41.262944",
        role: "Geriatric Care",
        status: "DECLINED",
    },
    {
        id: "5c9fd47a-6ed8-43fb-852f-962226a6b393",
        employeeID: 83680,
        firstName: "Joanna",
        lastName: "Hunter",
        shiftStart: "2023-06-21T03:04:52.262944",
        shiftEnd: "2023-06-21T06:04:52.262944",
        role: "Medicine Administration",
        status: "PENDING",
    },
    {
        id: "9355c983-9718-431c-a475-e3d7c0793773",
        employeeID: 76900,
        firstName: "Nicholas",
        lastName: "Rodriguez",
        shiftStart: "2022-11-30T14:43:00.262944",
        shiftEnd: "2022-11-30T23:43:00.262944",
        role: "Mental Health",
        status: "PENDING",
    },
    {
        id: "3f6d8e37-0b67-42fb-87dd-4e307ff2d71f",
        employeeID: 77771,
        firstName: "Yesenia",
        lastName: "Wilson",
        shiftStart: "2022-10-08T00:10:33.262944",
        shiftEnd: "2022-10-08T07:10:33.262944",
        role: "Rehabilitation",
        status: "CONFIRMED",
    },
    {
        id: "f8730856-4062-4dea-90ff-4a302301e59a",
        employeeID: 25796,
        firstName: "Andrea",
        lastName: "Webster",
        shiftStart: "2022-05-28T13:19:08.262944",
        shiftEnd: "2022-05-28T16:19:08.262944",
        role: "Geriatric Care",
        status: "PENDING",
    },
    {
        id: "81c96e4f-5641-403d-9e55-923e845f364a",
        employeeID: 91473,
        firstName: "Mitchell",
        lastName: "Wright",
        shiftStart: "2023-03-23T10:30:28.262944",
        shiftEnd: "2023-03-23T15:30:28.262944",
        role: "Community Health",
        status: "CONFIRMED",
    },
    {
        id: "fbbf5bd3-6483-445f-bdf2-0ddac57ed532",
        employeeID: 54238,
        firstName: "Thomas",
        lastName: "Rice",
        shiftStart: "2023-02-27T17:16:08.262944",
        shiftEnd: "2023-02-28T01:16:08.262944",
        role: "Wound Care",
        status: "PENDING",
    },
    {
        id: "0294b5db-358f-416a-8469-c0f8b1a2800d",
        employeeID: 11710,
        firstName: "Jeremy",
        lastName: "Mcknight",
        shiftStart: "2022-11-01T08:49:29.262944",
        shiftEnd: "2022-11-01T16:49:29.262944",
        role: "Pediatric Care",
        status: "DECLINED",
    },
    {
        id: "83f4d759-b6d4-435c-892e-e869e843282c",
        employeeID: 61186,
        firstName: "Kristin",
        lastName: "James",
        shiftStart: "2022-12-12T01:43:13.262944",
        shiftEnd: "2022-12-12T05:43:13.262944",
        role: "Medicine Administration",
        status: "CONFIRMED",
    },
    {
        id: "493e2d4d-4ae2-4013-a4c4-828aed3729ee",
        employeeID: 56920,
        firstName: "Amanda",
        lastName: "Ward",
        shiftStart: "2023-06-21T13:48:06.262944",
        shiftEnd: "2023-06-21T17:48:06.262944",
        role: "Occupational Health",
        status: "PENDING",
    },
    {
        id: "5d646d4a-240c-458a-9f1a-5180d4b704d0",
        employeeID: 38573,
        firstName: "Daniel",
        lastName: "Roberts",
        shiftStart: "2022-07-17T18:51:32.262944",
        shiftEnd: "2022-07-17T22:51:32.262944",
        role: "Medicine Administration",
        status: "DECLINED",
    },
    {
        id: "05e44693-1464-4edf-9b3f-9c9b8a53bfd8",
        employeeID: 49804,
        firstName: "Michelle",
        lastName: "Hancock",
        shiftStart: "2023-08-24T22:26:52.262944",
        shiftEnd: "2023-08-25T04:26:52.262944",
        role: "Occupational Health",
        status: "CONFIRMED",
    },
    {
        id: "6f45f493-4432-4497-bd84-7700eec59669",
        employeeID: 63876,
        firstName: "William",
        lastName: "Bradley",
        shiftStart: "2022-04-23T10:15:31.262944",
        shiftEnd: "2022-04-23T22:15:31.262944",
        role: "Community Health",
        status: "PENDING",
    },
    {
        id: "0bbedd41-ac89-45f5-bde7-96ec99c0e350",
        employeeID: 42345,
        firstName: "Douglas",
        lastName: "Cook",
        shiftStart: "2023-07-06T15:04:10.262944",
        shiftEnd: "2023-07-07T03:04:10.262944",
        role: "Community Health",
        status: "PENDING",
    },
]
