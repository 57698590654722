export const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 16,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1800 },
        items: 8,
    },
    largeTablet: {
        breakpoint: { max: 1800, min: 1400 },
        items: 6,
    },
    tablet: {
        breakpoint: { max: 1400, min: 900 },
        items: 4,
    },
    largeMobile: {
        breakpoint: { max: 900, min: 680 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 680, min: 0 },
        items: 1,
    },
}
