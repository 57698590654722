const stringToColor = (string) => {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = "#"

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xee
        color += `00${value.toString(16)}`.slice(-2)
    }
    /* eslint-enable no-bitwise */

    return color
}

// This creates a consistent color for each shift role
export const stringToColorDot = (name) => {
    return {
        sx: {
            bgcolor: stringToColor(name),
            height: "7px",
            width: "7px",
            marginRight: "5px",
        },
        children: "",
    }
}
