import { TextField, Select, MenuItem, InputLabel, FormControl, Stack, Button } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import "./Header.scss"

const Header = ({ searchTerm, setSearchTerm, statusFilter, setStatusFilter, updateShifts, selectedShiftIDs }) => {
    return (
        <header className="header">
            <div className="header__search-filter">
                <TextField
                    id="outlined-search"
                    type="search"
                    label="Search Caregiver"
                    placeholder="Type name to search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
                />
                <div>
                    <FormControl fullWidth>
                        <InputLabel id="shift-status-select-label">Shift Status</InputLabel>
                        <Select
                            labelId="shift-status-select-label"
                            id="shift-status-select"
                            label="Shift Status"
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                        >
                            <MenuItem value="ALL">All</MenuItem>
                            <MenuItem value="PENDING">Pending</MenuItem>
                            <MenuItem value="CONFIRMED">Confirmed</MenuItem>
                            <MenuItem value="DECLINED">Declined</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className="header__action">
                <div className="header__action__text">
                    <Stack spacing={2} direction="row">
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={(e) => updateShifts(e, selectedShiftIDs, "DECLINED")}
                        >
                            Decline Selected
                        </Button>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: "#00AF7C" }}
                            onClick={(e) => updateShifts(e, selectedShiftIDs, "CONFIRMED")}
                        >
                            Confirm Selected
                        </Button>
                    </Stack>
                </div>
                <div className="header__action__icon">
                    <Stack spacing={2} direction="row">
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={(e) => updateShifts(e, selectedShiftIDs, "DECLINED")}
                        >
                            <CloseIcon />
                        </Button>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: "#00AF7C" }}
                            onClick={(e) => updateShifts(e, selectedShiftIDs, "CONFIRMED")}
                        >
                            <CheckIcon />
                        </Button>
                    </Stack>
                </div>
            </div>
        </header>
    )
}

export default Header
